import React from 'react';
import { Link } from 'gatsby';
import { overlay } from '../utils/helpers';

import '../styles/card.scss';

const Card = ({ title, bg, url }) => (
  <Link to={url} className="card">
    <div className="bg" style={{ backgroundImage: `${overlay}, url(${bg || ''})` }}></div>
    <h2 className="card-title">{title}</h2>
  </Link>
);

export default Card;
