import { getSrc } from 'gatsby-plugin-image';

export const overlay = 'linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.6))';

export const mapPhotoSwipeImages = data =>
  data.reduce(
    (acc, cur) => ({
      small: [...acc.small, ...[cur.node.childImageSharp.small]],
      large: [
        ...acc.large,
        ...[
          {
            src: getSrc(cur.node.childImageSharp.large),
            h: cur.node.childImageSharp.large.height,
            w: cur.node.childImageSharp.large.width
          }
        ]
      ]
    }),
    { small: [], large: [] }
  );
