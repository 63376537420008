import React, { useRef, useEffect } from 'react';
import '../styles/grid.scss';

const Grid = ({ mode, children }) => {
  const itemRefs = useRef([...Array(children.length)].map(() => React.createRef()));

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3
    };

    const intersectionCallback = (entries) => {
      entries.filter((entry) => entry.isIntersecting).forEach((entry, index) => {
        setTimeout(() => {
          entry.target.classList.remove('is-hidden');
        },index * 300);
      });
    };

    const observer = new IntersectionObserver(intersectionCallback, options);

    itemRefs.current.forEach((itemRef) => {
      observer.observe(itemRef.current);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={`grid ${mode || ''}`}>
      <ul>
        {children.map((item, i) => (
          <li key={i} ref={itemRefs.current[i]} className="is-hidden">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Grid;
