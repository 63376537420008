import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Content from '../components/content';
import Grid from '../components/grid';
import Card from '../components/card';
import { getSrc } from 'gatsby-plugin-image';

const Projects =  ({ data }) => {
  const metaData = {
    title: 'Обекти'
  };
  return (
    <Fragment>
      <Layout metaData={metaData}>
      <Content type="full">
        <Grid>
          {data.allMarkdownRemark.edges.map((item, i) => {
            const project = item.node.frontmatter;
            return (
              <Card
                key={`key${i}`}
                title={project.title}
                bg={project.coverImage ? getSrc(project.coverImage) : null}
                url={item.node.fields.slug.slice(9, item.node.fields.slug.length - 1)}
              />
            );
          })}
        </Grid>
      </Content>
      </Layout>
    </Fragment>
  );
};

export default Projects;

export const query = graphql`
  query prjCategories {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: true } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            index
            title
            coverImage {
              ...largeImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;